*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: var(--body-font-regular);
}

:root{
  --light: #F8F5F0;
  --light2: #EBE5E1;
  --green: #D2EF9A;
  --red: #DB4444;
  --dark: #212121;
}

ul{
  list-style: none;
}

.bg-brand-light{
  background-color: var(--light) !important;
}
.text-brand-light{
  color: var(--light) !important;
}

.bg-brand-light2{
  background-color: var(--light2) !important;
}
.text-brand-light2{
  color: var(--light2) !important;
}

.bg-brand-green{
  background-color: var(--green) !important;
}
.text-brand-green{
  color: var(--green) !important;
}
.border-brand-green{
  border-color: var(--green) !important;
}
.btn-brand-green{
  background-color: var(--green) !important;
}
.btn-brand-green:hover{
  background-color: #bae368 !important;
}

.bg-brand-red{
  background-color: var(--red) !important;
}
.text-brand-red{
  color: var(--red) !important;
}
.border-brand-red{
  border-color: var(--red) !important;
}
.btn-brand-red{
  background-color: var(--red) !important;
  color: white;
}
.btn-brand-red:hover{
  background-color: #be2f2f !important;
  color: white;
}

.bg-brand-dark{
  background-color: var(--dark) !important;
}
.text-brand-dark{
  color: var(--dark) !important;
}
.border-brand-dark{
  border-color: var(--dark) !important;
}
.btn-brand-dark{
  background-color: var(--dark) !important;
  color: white;
}
.btn-brand-dark:hover{
  background-color: #191919 !important;
  color: white;
}







.overflow-hidden{
  overflow: hidden;
}

.w-max-content{
  width: max-content !important;
}

.rounded-brand{
  border-radius: 15px;
}

.rounded-brand-lg{
  border-radius: 20px;
}

.rounded-brand-sm{
  border-radius: 10px;
}

.cursor-pointer{
  cursor: pointer;
}

.fs-sm{
  font-size: 14px !important;
}

.full-overlay{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 1000;
}

.letter-spacing{
  letter-spacing: 2px;
}