.drop-wrap{
    position: relative;
}

.drop-options{
    position: absolute;
    display: none;
}

.price-range-input{
    position: relative;
}

.price-range-tracker{
    display: block;
    /* background: linear-gradient(to right, red 0%, red 14.36%, blue 14.36%, blue 52.8%, red 52.8%, red 100%); */
    width: 100%;
    height: 5px;
    border-radius: 5px;
}

.range-input{
    position: absolute;
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    right: 0;
}

.max-range{
    width: 90%;
}

.range-input::-webkit-slider-runnable-track{
    width: 100%;
    height: 0px;
    background-color: green;
    appearance: none;
    -webkit-appearance: none;
    /* background-color: red; */
}

.range-input::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid black;
    z-index: 5;
    position: relative;
    margin-top: -6px;
    cursor: pointer;
}

.min-range{
    /* -webkit-appearance: none; */
    appearance: none;
    width: 100%;
}

.filter-color{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0;
}

.overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(227, 227, 150, 0.4);
    border-radius: 50%;
}

.filter-icon{
    display: none;
}

.filter-cross{
    display: none;
}

.desk-extra{
    display: none;
}

@media screen and (width < 800px) {
    .filter-side{
        display: flex ;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0px;
        right: 0px;
        background-color: rgb(255, 255, 255);
        width: 100%;
        height: 100%;
        z-index: 999;
    }
    .filter-icon{
        display: block;
    }
    .filter-side .extra-filter{
        display: block;
    }
    .extra-filter-out{
        display: none;
    }
    .filter-cross{
        display: flex;
    }
    .desk-extra{
        display: flex;
    }
}