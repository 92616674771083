.product-color{
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.detail-img-wrap{
    position: sticky;
    top: 80px;
    height: max-content;
}

.detail-img-wrap .slick-dots{
    position: absolute;
    top: 5px;
    left: -111px;
    bottom: 10px !important;
    display: flex !important;
    justify-content: flex-start;
    flex-direction: column;
    width: max-content;
    /* background-color: red; */
    margin: auto;
    /* transition: 0.5s all; */
}

.detail-img-wrap .slick-dots li{
    width: 60px;
    height: 60px;
    margin-bottom: 30px;
    /* background-color: red; */
}

.detail-img-wrap .slick-dots li button{
    width: 60px;
    height: 60px;
}

.detail-img-wrap .slick-dots li button:before {
    content: '';
    position: unset;
    width: 60px;
    height: 60px;
    text-align: center;
    display: flex;
    opacity: 1;
    border-radius: 10px !important;
    /* background-image: url('https://assets.ajio.com/medias/sys_master/root/20230804/z6z8/64cd2628eebac147fca8b62a/-1117Wx1400H-466421949-beige-MODEL2.jpg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-font-smoothing: antialiased;
    align-items: center;
    justify-content: center;
    -moz-osx-font-smoothing: grayscale;
}

.dot-img{
    opacity: 1;
    border-radius: 7px;
    overflow: hidden;
    width: 80px;
}

.dot-img img{
    mix-blend-mode: multiply !important;
}

.slick-dots li.slick-active .dot-img img {
    border-radius: 7px;
    overflow: hidden;
    border: 1px solid #000; /* Change this color to your preferred active border color */
}

.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    display: none !important;
    outline: none;
    background: transparent;
}

.mob-buy-btn{
    display: none;
    z-index: 999;
}

.hovering-heart{
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logpop-wrapper{
    align-items: end;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.logpop-wrapper .logpop-wrap{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.logpop-wrap{
    width: min(400px, 100%);
}

.confirmation-wrap{
    flex-direction: column;
    justify-content: flex-end !important;
}

.confirmation-pop{
    width: min(100%, 600px);
    height: 80vh;
    overflow-y: auto !important;
}

.confirmation-pop-cross{
    width: min(100%, 600px);
}

.cross-para{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba(236, 236, 236, 0.585);
}

.confirmation-pop::-webkit-scrollbar{
    width: 3px;
    background-color: lightgray;
}

.confirmation-pop::-webkit-scrollbar-thumb{
    background-color: rgb(181, 181, 181);
}

.mob-order-btn-sm{
    display: none;
}

.arrow-para{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4cc08;
}

@media screen and (width < 800px) {
    .detail-img-wrap .slick-dots{
        position: relative;
        top: unset;
        left: unset;
        bottom: 10px !important;
        display: flex !important;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
        height: 120px;
        overflow: auto;
        /* background-color: red; */
        margin: auto;
        margin-top: 20px;
        margin-bottom: -20px;
        /* transition: 0.5s all; */
    }
    .detail-img-wrap .slick-dots li{
        width: 100px;
        height: 80px;
        margin-bottom: -20px;
        /* background-color: red; */
    }
    .dot-img{
        opacity: 1;
        border-radius: 7px;
        overflow: hidden;
        width: 100px;
    }
    .mob-buy-btn{
        display: flex;
        width: 100%;
        position: fixed;
        right: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
    }
    .desk-buy-btn{
        display: none;
    }
    .detail-img-wrap{
        position: unset;
        top: unset;
        height: unset;
    }
    .mob-order-btn-sm{
        display: block;
    }
}

.accordion-button:not(.collapsed){
    background-color: white !important;
    border-bottom: 1px solid lightgray !important;
}