.order-list .row:nth-child(even){
    background-color: var(--light);
}

.das-tabs-wrap{
    overflow-x: auto;
}

@media screen and (width > 800px) {
    .mob-order{
        display: none;
    }
}