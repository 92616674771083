.collection-card{
    width: 100%;
    /* height: 400px; */
    aspect-ratio: 1/1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.collection-btn{
    background-color: white;
}

.collection-btn:hover{
    background-color: var(--dark);
    color: white;
}

@media screen and (width < 800px) {
    .collection-card{
        /* height: 200px; */
        aspect-ratio: 1/1;
    }
    .collection-btn{
        font-size: 14px;
    }
}