.form-container{
    width: min(600px, 100%);
    border-radius: 50px;
}

.popError-wrap{
    width: min(600px, 95%);
    background-color: white;
}

.error-pop-wrap{
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 55;
}