.checkout-div{
    position: sticky;
    top: 70px;
}

.main-cart{
    min-height: 90vh;
}

.hover-checkout-wrap{
    display: none;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.checkout-wrap{
    align-items: flex-end;
}

.checkout-popup{
    width: min(100%, 500px);
    min-height: 40vh;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.order-details{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    border-radius: 0px !important;
    overflow-y: scroll !important;
}

.order-header-wrap{
    position-try: relative;
}

.order-back-btn{
    position: absolute;
    top: 10px;
    left: 10px;
}

@media screen and (width < 800px) {
    .checkout-div{
        position: unset;
    }
    .hover-checkout-wrap{
        display: block;
    }
}